import React from "react";
import AboutBackground from "../Assets/about-background.png";

const About = () => {
  return (
    <div id="about" className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
          Our Solution:
        </h1>
        <h1 className="primary-heading">
          The box and the app
        </h1>
        <p className="primary-text">
        Sera emerged as a startup upon recognizing significant potential for improvement in the operational workflows of car dealerships.
        </p>
        <p className="primary-text">
        Our comprehensive solution comprises a physical distribution box for managing keys and a corresponding application. 
        This innovative system facilitates key operations, dealership management, and provides valuable data insights to optimize overall workflow efficiency.
        </p>
        <p className="primary-text">
        This innovative system facilitates key operations, dealership management, and provides valuable data insights to optimize overall workflow efficiency.
        </p>
        <div className="about-buttons-container">
          <button className="secondary-button">Learn More</button>
          {/* <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default About;
