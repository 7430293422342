import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Logo from "../Assets/logo_serabox.png";
import { HiOutlineBars3 } from "react-icons/hi2";

const Navbar = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" width="250" />
      </div>
      <div className="navbar-links-container">
        <Link to="home" smooth={true} duration={500}>
          Home
        </Link>
        <Link to="about" smooth={true} duration={500}>
          About
        </Link>
        <Link to="contact" smooth={true} duration={500}>
          <button className="primary-button">Contact Us</button>
        </Link>
        
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={scrollToTop} />
      </div>
    </nav>
  );
};

export default Navbar;
