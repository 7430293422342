import React, { useState } from "react";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Replace 'YOUR_FORMSPREE_ENDPOINT' with your actual Formspree endpoint
    const formUrl = "https://formspree.io/f/mjvnonrl";
    try {
      const response = await fetch(formUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        console.log("Email sent successfully");
        setSuccessMessage("Thanks we will be in touch soon!");
        setErrorMessage("");
      } else {
        console.error("Failed to send email");
        setErrorMessage("Failed to send email. Please try again later.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setErrorMessage("Error sending email. Please try again later.");
      setSuccessMessage("");
    }
  };

  return (
    <div id="contact" className="contact-page-wrapper">
      <h1 className="primary-heading">Want to know more?</h1>
      <h1 className="primary-heading">Let's get in touch</h1>
      <form className="contact-form-container" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="yourmail@gmail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="secondary-button">
          Submit
        </button>
      </form>
      {successMessage && <p>{successMessage}</p>}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default Contact;
